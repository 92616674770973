import { AvailableChain, AvailableTokenProtocol } from './available'
import { ContractInstanceInfo } from './contracts'

const unavailableContractInstances = <T extends AvailableChain>(
  ...chains: T[]
) => {
  const instances = {}

  for (const chain of chains) {
    Object.defineProperty(instances, chain, {
      get: () => {
        throw new Error(
          `Contract instance is not available for the ${chain} chain`
        )
      },
    })
  }

  return instances as {
    [K in T]: ContractInstanceInfo<K>
  }
}

enum RegisteredToken {
  DTM = 'DTM',
  LTX = 'LTX',
  OBS = 'OBS',
  veLTX = 'veLTX',
}

const RegisteredTokens: Record<
  RegisteredToken,
  {
    tokenProtocol: AvailableTokenProtocol
    decimals: number
    instances: { [T in AvailableChain]: ContractInstanceInfo<T> }
  }
> = {
  [RegisteredToken.DTM]: {
    tokenProtocol: AvailableTokenProtocol.ERC721,
    decimals: 0,
    instances: {
      [AvailableChain.ETHEREUM_MAINNET]: {
        chain: AvailableChain.ETHEREUM_MAINNET,
        address: '0x9c21573d2cd8885a9221206dc3e7cf1d18d39998',
      },
      [AvailableChain.ETHEREUM_SEPOLIA]: {
        chain: AvailableChain.ETHEREUM_SEPOLIA,
        address: '0xef3275d3b794e7868a9b1569dac1ccaf220f515d',
      },
      ...unavailableContractInstances(
        AvailableChain.POLYGON_MAINNET,
        AvailableChain.POLYGON_MUMBAI,
        AvailableChain.CONSTELLATION_MAINNET,
        AvailableChain.CONSTELLATION_TESTNET
      ),
    },
  },
  [RegisteredToken.LTX]: {
    tokenProtocol: AvailableTokenProtocol.ERC20,
    decimals: 8,
    instances: {
      [AvailableChain.ETHEREUM_MAINNET]: {
        chain: AvailableChain.ETHEREUM_MAINNET,
        address: '0xa393473d64d2f9f026b60b6df7859a689715d092',
      },
      [AvailableChain.ETHEREUM_SEPOLIA]: {
        chain: AvailableChain.ETHEREUM_SEPOLIA,
        address: '0x40C83Dda7dd7CdDe18eabbBdEe0B1d4F033721b5',
      },
      ...unavailableContractInstances(
        AvailableChain.POLYGON_MAINNET,
        AvailableChain.POLYGON_MUMBAI,
        AvailableChain.CONSTELLATION_MAINNET,
        AvailableChain.CONSTELLATION_TESTNET
      ),
    },
  },
  [RegisteredToken.OBS]: {
    tokenProtocol: AvailableTokenProtocol.ERC20,
    decimals: 16,
    instances: {
      [AvailableChain.ETHEREUM_MAINNET]: {
        chain: AvailableChain.ETHEREUM_MAINNET,
        address: '0xafd9268fdfebee905f7439b12c943bc18ad293c2',
      },
      ...unavailableContractInstances(
        AvailableChain.ETHEREUM_SEPOLIA,
        AvailableChain.POLYGON_MAINNET,
        AvailableChain.POLYGON_MUMBAI,
        AvailableChain.CONSTELLATION_MAINNET,
        AvailableChain.CONSTELLATION_TESTNET
      ),
    },
  },
  [RegisteredToken.veLTX]: {
    tokenProtocol: AvailableTokenProtocol.ERC20,
    decimals: 18,
    instances: {
      [AvailableChain.ETHEREUM_MAINNET]: {
        chain: AvailableChain.ETHEREUM_MAINNET,
        address: '0xc6a22cc9acd40b4f31467a3580d4d69c3387f349',
      },
      [AvailableChain.ETHEREUM_SEPOLIA]: {
        chain: AvailableChain.ETHEREUM_SEPOLIA,
        address: '0x9F89d9A1Ffe7B1414ebb9A11Cd266ACd7dA0521b',
      },
      ...unavailableContractInstances(
        AvailableChain.POLYGON_MAINNET,
        AvailableChain.POLYGON_MUMBAI,
        AvailableChain.CONSTELLATION_MAINNET,
        AvailableChain.CONSTELLATION_TESTNET
      ),
    },
  },
}

export { RegisteredToken, RegisteredTokens }

import React, { useEffect } from 'react'
import { useNavigate, NavLink, Outlet } from 'react-router-dom'
import dayjs from 'dayjs'

import { useSignInRedirect } from '@lattice/common/hooks'
import { useUserProvider } from '@lattice/common/providers'

import styles from './view.module.scss'

export const MyAccountView = () => {
  const { user } = useUserProvider()
  const navigate = useNavigate()

  const signInUrl = useSignInRedirect()

  useEffect(() => {
    if (!user) {
      navigate(signInUrl)
    }
  }, [user])

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h1>My Account</h1>
        <div className={styles.sections}>
          <div className={styles.navbar}>
            <NavLink to="profile">Profile Settings</NavLink>
            {/**
             * Following logic should be removed/reverted (commit) after the specified date
             * not necessary for upcomming releases
             */}
            {dayjs().isAfter(dayjs('2024-09-04T20:00:00.000Z')) && (
              <NavLink to="socials-wallets">Socials and Wallets</NavLink>
            )}
            <NavLink to="security">Security</NavLink>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

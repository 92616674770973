import { useMutation, useQuery } from '@tanstack/react-query'

import { useUserProvider } from '@lattice/common/providers'

import { getUserTwitterXStats, registerOptinSocialWallets } from '../user'

export const useUserQueries = () => {
  const { user, doFetchUserDbData } = useUserProvider()

  const twitterxStats = useQuery({
    queryKey: ['user', 'external_integrations', 'twitter_x', 'stats'],
    queryFn: async (qfnContext) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      return getUserTwitterXStats({ qfnContext })
    },
    enabled: !!user,
  })

  const optinSocialWalletsMut = useMutation({
    onMutate: async () => {},
    mutationFn: async (values: { consent: boolean }) => {
      if (!user) {
        throw new Error('User must be logged in')
      }
      await registerOptinSocialWallets(values.consent, {})
    },
    onSettled: async (response, error, data, context) => {
      if (!context) {
        return
      }
    },
    onSuccess: async () => {
      await doFetchUserDbData()
    },
    onError: async (error) => {
      console.log(error)
    },
  })

  return { twitterxStats, optinSocialWalletsMut }
}

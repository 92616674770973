import React, { useEffect } from 'react'
import cls from 'classnames'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

import {
  HeroCard,
  Content,
  Typography,
  GridContainer,
  PendingContent,
  Banner,
} from '@lattice/common/components'
import {
  useLaunchpadProvider,
  ILaunchpadProject,
} from '@lattice/common/providers'
import { formatNumberAndCurrency, NumberFormat } from '@lattice/utils'
import { FetchStatus } from '@lattice/common/consts'
import { getStatusBadgeData } from '@lattice/common/providers/LaunchpadProvider/utils'

import styles from './view.module.scss'

const ProjectStatusSortOrder: Record<ILaunchpadProject['status'], number> = {
  draft: 0,
  coming_soon: 1,
  live: 2,
  allocating: 3,
  funding: 4,
  distributing: 5,
  complete: 6,
}

const ProjectsView = () => {
  const {
    projects: { resource: projects, ...fetchStatus },
    requestProjects,
  } = useLaunchpadProvider()

  useEffect(() => {
    requestProjects()
  }, [])

  return (
    <>
      <Banner>
        Don't forget to link your X account and DAG wallet to earn El Paca!{' '}
        <Link to="/user/myaccount/socials-wallets">Link Accounts →</Link>
      </Banner>
      <Content>
        <Typography.PageTitle>
          {t('views.Launchpad.title', 'Launchpad')}
        </Typography.PageTitle>
        {/* <section>
        <FilterRow>
          <FilterDropdown
            options={[
              {
                value: 'all',
                content: t(
                  'views.Launchpad.filterRow.projects.options.all',
                  'All projects'
                ),
              },
            ]}
          >
            {t('views.Launchpad.filterRow.projects.title', 'All projects')}
          </FilterDropdown>
          <FilterDropdown
            options={[
              {
                value: 'all',
                content: t(
                  'views.Launchpad.filterRow.statuses.options.all',
                  'All statuses'
                ),
              },
            ]}
          >
            {t('views.Launchpad.filterRow.statuses.title', 'All statuses')}
          </FilterDropdown>
        </FilterRow>
      </section> */}
        <GridContainer>
          {projects
            .sort(
              (pA, pB) =>
                ProjectStatusSortOrder[pA.status] -
                ProjectStatusSortOrder[pB.status]
            )
            .map((project) => (
              <HeroCard
                key={project.id}
                headerImage={project.logoIconUrl}
                data={{
                  title: project.name,
                  subTitle: project.summaryText,
                  nodeType: 'Soft Node',
                  buttonLabel: 'View project',
                  to: project.slug,
                  displayInfo: [
                    {
                      label: 'Status',
                      badge: getStatusBadgeData(project.status),
                    },
                    {
                      label: 'Timeline',
                      text: dayjs().isBefore(dayjs(project.startsAt))
                        ? `Starts ${dayjs(project.startsAt).fromNow()}`
                        : dayjs().isBefore(dayjs(project.stakingEndsAt))
                          ? `Ends ${dayjs(project.stakingEndsAt).fromNow()}`
                          : `Ended ${dayjs(project.stakingEndsAt).fromNow()}`,
                    },
                    {
                      label: 'Target Raise',
                      text: formatNumberAndCurrency(
                        project.targetRaiseAmount,
                        project.targetRaiseCurrency,
                        NumberFormat.MILLIFY
                      ),
                    },
                    {
                      label: 'Total Staked',
                      text: formatNumberAndCurrency(
                        project.totalStaked['all'],
                        'LTX',
                        NumberFormat.MILLIFY
                      ),
                    },
                  ],
                }}
                className={{
                  header: cls(styles.bountyHeader),
                  footer: cls(styles.footer),
                }}
              />
            ))}
        </GridContainer>
        {fetchStatus.status === FetchStatus.PENDING && <PendingContent />}
      </Content>
    </>
  )
}

export { ProjectsView }

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

import {
  HeroCard,
  Content,
  Typography,
  GridContainer,
  Banner,
} from '@lattice/common/components'
import {
  useSoftNodesProvider,
  useUserProvider,
} from '@lattice/common/providers'

import { GeneralInfoContainer } from './components/GeneralInfoContainer'
import { SoftNodeStakingCard } from './components'
import styles from './view.module.scss'
import { Separator } from './components/Separator'
import { GovernanceCard } from './components/GovernanceCard'
import { GettingStartedCard } from './components/GettingStartedCard'
import { useFeaturedCards } from './hooks'

const DashboardView = () => {
  const { user } = useUserProvider()
  const softNodesInfo = useSoftNodesProvider()
  const { cards } = useFeaturedCards()
  const [circulatingSupply, setCirculatingSupply] = useState<
    number | undefined
  >()
  const getCirculatingSupply = async () => {
    let data
    try {
      ;({ data } = await axios({
        method: 'GET',
        url: 'https://l0-lb-mainnet.constellationnetwork.io/dag/total-supply',
      }))
    } catch (e) {
      throw e
    }
    if (data.total) {
      setCirculatingSupply(data.total)
    }
  }

  useEffect(() => {
    getCirculatingSupply()
  }, [])

  return (
    <>
      <Banner>
        Don't forget to link your X account and DAG wallet to earn El Paca!{' '}
        <Link to="/user/myaccount/socials-wallets">Link Accounts →</Link>
      </Banner>
      <Content>
        <Typography.PageTitle>Dashboard</Typography.PageTitle>
        <GeneralInfoContainer>
          <GettingStartedCard />
          <GovernanceCard />
          <div className={!user ? styles.positionSingleCard : undefined}>
            <SoftNodeStakingCard
              projects={softNodesInfo.projects.resource.slice(0, 3)}
              circSupply={circulatingSupply}
            />
          </div>
        </GeneralInfoContainer>
        <Separator>Featured Programs</Separator>
        <GridContainer>
          {cards.map((cardData) => {
            return (
              <HeroCard
                key={cardData.key}
                headerImage={cardData.headerImage}
                headerLogo={cardData.headerLogo}
                data={cardData.data}
                variant={'flexContent'}
              />
            )
          })}
        </GridContainer>
      </Content>
    </>
  )
}

export { DashboardView }
